
// -owner-group-others-
// -rwxrwxrwx = 0777
enum FileAccess {
    None,
    Read = 1 << 2,
    Write = 1 << 1,
    ReadWrite  = Read | Write,
    Execute = 1 << 0,
    ReadWriteExecute = ReadWrite | Execute,
    ExecuteRead = Execute | Read,
    r = Read,
    w = Write,
    x = Execute,
    rw = ReadWrite,
    rwx = ReadWriteExecute,
    xr = ExecuteRead
}

/**
 * This table lists some important MIME types for the Web copied from
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 */
export enum FileUploadTypes {
    aac = 'audio/aac',
    abw = 'application/x-abiword',
    arc = 'application/octet-stream',
    avi = 'video/x-msvideo',
    azw = 'application/vnd.amazon.ebook',
    bin = 'application/octet-stream',
    bmp = 'image/bmp',
    bz = 'application/x-bzip',
    bz2 = 'application/x-bzip2',
    csh = 'application/x-csh',
    css = 'text/css',
    csv = 'text/csv',
    doc = 'application/msword',
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    eot = 'application/vnd.ms-fontobject',
    epub = 'application/epub+zip',
    es = 'application/ecmascript',
    gif = 'image/gif',
    html = 'text/html',
    ico = 'image/x-icon',
    ics = 'text/calendar',
    jar = 'application/java-archive',
    jpg = 'image/jpeg',
    js = 'application/javascript',
    json = 'application/json',
    midi = 'audio/midi',
    mpeg = 'video/mpeg',
    mpkg = 'application/vnd.apple.installer+xml',
    odp = 'application/vnd.oasis.opendocument.presentation',
    ods = 'application/vnd.oasis.opendocument.spreadsheet',
    odt = 'application/vnd.oasis.opendocument.text',
    oga = 'audio/ogg',
    ogv = 'video/ogg',
    ogx = 'application/ogg',
    otf = 'font/otf',
    png = 'image/png',
    pdf = 'application/pdf',
    ppt = 'application/vnd.ms-powerpoint',
    pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rar = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rtf = 'application/rtf',
    sh = 'application/x-sh',
    svg = 'image/svg+xml',
    swf = 'application/x-shockwave-flash',
    tar = 'application/x-tar',
    tiff = 'image/tiff',
    ts = 'application/typescript',
    ttf = 'font/ttf',
    vsd = 'application/vnd.visio',
    wav = 'audio/wav',
    weba = 'audio/webm',
    webm = 'video/webm',
    webp = 'image/webp',
    woff = 'font/woff',
    woff2 = 'font/woff2',
    xhtml = 'application/xhtml+xml',
    xls = 'application/vnd.ms-excel',
    xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml = 'application/xml',
    xul = 'application/vnd.mozilla.xul+xml',
    zip = 'application/zip',
    '3gp' = 'video/3gpp',
    '3g2' = 'video/3gpp2',
    '7z' = 'application/x-7z-compressed'
}
